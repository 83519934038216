<template>
  <div class="pa-0" style="height: 100vh">
    <v-dialog persistent max-width="500" v-model="accountSuspended">
      <v-card class="white--text" color="error">
        <v-container>
          <v-card-title> Ops, your account has been suspended </v-card-title>
          <v-card-text>
            Contact
            <a
              class="white--text font-weight-bold text-decoration-none"
              href="mailto:info@snappyexchange"
              >Snappy exChange</a
            >
            for more information
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <v-row class="h-100" no-gutters>
      <v-col
        class="hidden-sm-and-down"
        md="7"
        style="background-color: #0d01c7"
      >
        <img
          src="@/assets/img/bg.jpg"
          width="100%"
          height="100%"
          alt="bg-image"
          style="width: 100%; height: 100%"
        />
      </v-col>
      <v-col cols="12" sm="8" md="5" class="mx-auto relative">
        <notification-message
          :message.sync="message"
          :type="message && error ? 'error' : 'success'"
        />
        <v-container class="d-flex align-center justify-center h-100">
          <v-card
            width="340"
            flat
            class="text-center animate__animated animate__zoomIn"
          >
            <v-img
              class="hidden-md-and-up"
              height="220"
              src="@/assets/img/bg.jpg"
            ></v-img>
            <v-col>
              <a
                class="black--text text-subtitle-1 d-flex justify-center align-center mb-4"
                href="https://snappyexchange.com"
              >
                <img :src="logoSnappy" width="45" height="45" />
                <span class="font-weight-bold">SnappyExchange</span>
              </a>
              <h3 class="font-semibold">Welcome back!</h3>
              <h5 class="font-weight-regular text-sm">
                Sign in into your account
              </h5>
              <small> </small>
            </v-col>
            <v-col>
              <v-form ref="loginForm" @submit.prevent="validate">
                <v-container class="h-100">
                  <v-text-field
                    :disabled="loading"
                    name="email"
                    type="email"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-email"
                    label="Email"
                    required
                    v-model="form.email"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                  <v-text-field
                    :disabled="loading"
                    :rules="[rules.required]"
                    outlined
                    rounded
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    name="password"
                    label="Password"
                    @click:append="showPassword = !showPassword"
                    v-model="form.password"
                  ></v-text-field>
                  <v-btn
                    :loading="loading"
                    class="mt-2"
                    block
                    rounded
                    color="primary"
                    elevation="2"
                    type="submit"
                    >Sign in</v-btn
                  >
                  <div class="d-flex justify-end mt-5">
                    <router-link
                      class="grey--text text--darken-2 text-decoration-none text-subtitle-2"
                      :to="`/forgot-password${
                        form.email !== '' ? `?email=${form.email}` : ''
                      }`"
                      >Forget password?</router-link
                    >
                  </div>
                </v-container>
              </v-form>
            </v-col>
            <p
              class="subtitle-2 grey--text text--darken-2 my-0 font-weight-bold"
            >
              New to Snappy exChange?
              <router-link
                class="text-decoration-none text--primary text--darken-1 font-weight-bold accent--text"
                to="/register"
                >Register</router-link
              >
            </p>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import rules from '../../utils/rules'
import logoSnappy from '../../assets/logo.png'
import api from '../../api/users/auth'
import notificationMessage from '../../components/notification/notificationMessage.vue'
import walletApi from '../../api/users/wallet'
import { mapState } from 'vuex'

export default {
  components: { notificationMessage },
  data() {
    return {
      logoSnappy,
      message: null,
      error: false,
      loading: false,
      showPassword: false,
      redirect: null,
      form: { email: '', password: '' },
      rules: {
        required: rules.required,
        email: rules.email
      }
    }
  },
  computed: {
    ...mapState({
      accountSuspended: (state) => state.security.accountSuspended
    })
  },
  created() {
    this.$store.commit('setTokenExpiredState', false)
    this.$store.commit('setAccountSuspended', false)
    this.redirect = this.$route.query.redirect
    this.$store.commit('user/setToken', '')
    this.$store.commit('user/updateUserData', {})

    this.$store.commit('user/setWalletData', {})
  },
  methods: {
    validate() {
      if (this.$refs.loginForm.validate()) {
        this.login()
      }
    },
    async login() {
      this.loading = true
      const res = await api
        .auth()
        .login({ email: this.form.email, password: this.form.password })

      if (res.error) {
        // this.message = res.errorMessage.message
        // this.error = true
        // console.log(res.errorMessage.message)
        // console.log(res.internalError)
        if (res.errorMessage.message === 'Please verify your email address') {
          this.$router.push(
            `/register/success?from=login&email=${this.form.email}`
          )
          return
        }
        this.loading = false
        this.$swal.fire({
          icon: 'error',
          title: 'Login error',
          text:
            res.errorMessage.message ||
            'Unable to login please check your internet connection'
        })
        return
      }

      if (res.data.data.twoFactorAuth) {
        this.$router.push(
          `/verify/account/${res.data.data.user._id}?username=${res.data.data.user.username}`
        )
        this.$store.commit('setAccountSuspended', false)
        return
      }

      this.$store.commit('user/setToken', '')
      this.$store.commit('user/updateUserData', {})

      this.$store.commit('user/setWalletData', {})

      this.message = 'Login successful'
      this.error = false
      const token = res.data.data.token
      const user = res.data.data.user
      this.$store.commit('user/setToken', token)
      this.$store.commit('user/updateUserData', user)

      const wallet = await walletApi.data().getWallet()

      this.$store.commit('user/setWalletData', wallet.data.data)
      if (this.accountSuspended) {
        return
      }
      setTimeout(() => {
        this.$store.commit('setAccountSuspended', false)
        if (this.redirect) {
          this.$store.dispatch('user/getCountryCurrency')
          this.$router.push(this.redirect)
          return
        }
        this.$router.push('/user/')
      }, 1500)
    }
  }
}
</script>

<style></style>
